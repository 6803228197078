import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/Program.webp";

export default function Header() {
  return (
    <section id="header">
      <SharedHeader
        image={header}
        classNameImage="header-image"
        align="right"
        titlesize="display-2"
        titlecolor="blue"
        title="Digitalisierung mitgestalten"
        text="Löse Herausforderungen der öffentlichen Verwaltung und lerne, digitale Lösungen in interdisziplinären Teams zu entwickeln."
        textsize="body-3"
        imageAltText=""
      />
    </section>
  );
}
